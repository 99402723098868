import '../Style/about.css';
import ME from '../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import {VscFolderLibrary} from 'react-icons/vsc';


function About(){
    return(
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className='container about__container'>
                <div className='about__me'>
                    <div className='about__me-image'>
                        <img src={ME} alt='me-about'/>
                    </div>
                </div>
            

            <div className='about__content'>
                <div className='about__cards'>
                    <article className='about__card'>
                        <FaAward className='about__icon' />
                        <h5>Education</h5>
                        <small> UNAM </small>
                    </article>
                
                    <article className='about__card'>
                        <FiUsers className='about__icon' />
                        <h5>Freelance</h5>
                        <small>Software Development</small>
                    </article>
                    
                    <article className='about__card'>
                        <VscFolderLibrary className='about__icon' />
                        <h5>Learning</h5>
                        <small> Mobile applications </small>
                    </article>
                </div>

                <p>
                    Enthusiastic self-taught web developer.
            
                </p>

                <a href="#contact" className='btn btn-primary'>Let's talk</a>
                </div>
            </div>
        

        </section>
    )
}

export default About;