import '../Style/footer.css';
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'

function Footer(){
    return(
        <footer>
            <a href="#" className='footer__logo'>HOPER</a>

            <ul className='permalinks'>
                <li><a href='#'>Home</a></li>
                <li><a href='#about'>About</a></li>
                <li><a href='#portfolio'>Portfolio</a></li>
                <li><a href='#experience'>Skills</a></li>
                <li><a href='#contact'>Contact</a></li>
            </ul>

            <div className='footer__socials'>
                <a href='https://www.facebook.com/ferrnandito'><FaFacebookF /></a>
                <a href='https://www.instagram.com/fernando_hopster/'><FiInstagram /></a>
            </div>

        </footer>
    )
}

export default Footer;