import '../Style/contact.css';
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import {useRef} from 'react';
import emailjs from 'emailjs-com'


function Contact(){
    const form = useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_u1lomp8', 'template_iyou0rt', form.current, 'V6BgFdpnSl4WuqaS5')
        
        e.target.reset()
    };

    return(
        <section id='contact'>
            <h5>Get In Touch</h5>   
            <h2>Contact Me</h2>

            <div className='container contact__container'>
                <div className='contact__options'>
                    <article className='contact__option'>
                        <MdOutlineEmail className='contact__option-icon'/>
                        <h4>Email</h4>
                        <h5>fernandocristobal0@gmail.com</h5>
                        <a href='mailto:fernandocristobal0@gmail.com' target='_blank'>Send a message</a>
                    </article>
                    <article className='contact__option'>
                        <RiMessengerLine className='contact__option-icon'/>
                        <h4>Messenger</h4>
                        <h5>Fernando Cristóbal</h5>
                        <a href='https://m.me/ferrnandito/' target='_blank'>Send a message</a>
                    </article>
                    <article className='contact__option'>
                        <BsWhatsapp className='contact__option-icon'/>
                        <h4>Whatsapp</h4>
                        <h5>+420 773 296 335</h5>
                        <a href='https://api.whatsapp.com/send?phone=+420773296335' target='_blank'>Send a message</a>
                    </article>
                </div>
                {/* END OF CONTACT OPTIONS*/}
                <form ref={form} onSubmit={sendEmail}>
                    <input type='text' name='name' placeholder='Your Full Name' required />
                    <input type='email' name='email' placeholder='Your Email' required />
                    <textarea type='message' rows='7' placeholder='Your Message' required ></textarea>
                    <button type='submit' className='btn btn-primary'>Send Message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact;