import '../Style/projects.css';
import IMG1 from '../assets/portfolio1.png'

function Projects(){
    return(
        <section id='portfolio'>
            <h5>My Recent Work</h5>
            <h2>Portafolio</h2>

            <div className='container portfolio__container'>
                <article className='portfolio__item'>
                    <div className='portfolio__item-image'>
											<img src={IMG1} alt='' />
										</div>
										<h3> "Tips Manager" </h3>
										<div className='portfolio__item-cta'>
											<a href="https://github.com" className='btn' target='_blank'>Github</a>
											<a href="https://alebrijesmanifestotips.com" className='btn btn-primary' target='_blank'>MORE INFO</a>
										</div>
								</article>
            </div>
            </section>
    )
}

export default Projects;